<template>
    <div class="main">
        <div class="data">

            <div style="display: flex;align-items: center;justify-content: center;width: 100%">
                <div style="width: 50%">
                    <p style="padding-left: 5px;color: #0000ff"> (S):스포츠베팅, (M):미니게임, (C):카지노, (SL):슬롯</p>
                </div>
                <div  style="width: 50%;text-align: right">
                    <el-button type="primary" size="mini" @click="addPartner(managerConst.Parnter.PATNER_GENERALAGENT_NOID,managerConst.MODE_SAVE)">부본사 등록</el-button>
                </div>
            </div>
            <table class="table100" v-for="(item,index) in partnerList" :key="index" style="margin-top: 10px">
                <tr>
                    <th style="width: 5%">번호</th>
                    <th style="width: 8%">아이디</th>
                    <th style="width: 10%">닉네임</th>
                    <th style="width: 6%">회원(블럭)</th>
                    <th style="width: 7%">가입코드</th>
                    <th style="width: 8%">보유머니</th>
                    <th style="width: 5%">손익금%</th>
                    <th style="width: 5%">(S)베팅%</th>
                    <th style="width: 5%">(M)베팅%</th>
                    <th style="width: 5%">(C)베팅%</th>
                    <th style="width: 5%">(SL)베팅%</th>
                    <th style="width: 4%">상태</th>
                    <th style="width: 4%">회원가입</th>
                    <th style="width: 4%">머니이동</th>
                    <th style="width: 8%">action</th>
                </tr>
                <!--총판정보-->
                <tr style="color: blue!important;">
                    <td style="background-color: #b5b5b5!important;">{{item.id}}</td>
                    <td style="background-color: #b5b5b5!important;">{{item.account}}</td>
                    <td style="background-color: #b5b5b5!important;">{{item.nickname}}</td>
                    <td style="background-color: #b5b5b5!important;">{{item.userCount}} ({{item.userCountBlock}})</td>
                    <td style="background-color: #b5b5b5!important;">-</td>
                    <td style="background-color: #b5b5b5!important;">{{item.cash|comma}}</td>
                    <td style="background-color: #b5b5b5!important;">{{item.rechargeProfitPercent}}%</td>
                    <td style="background-color: #b5b5b5!important;">{{item.betProfitPercent}}%</td>
                    <td style="background-color: #b5b5b5!important;">{{item.betLeisureGivebackProfitPercent}}%</td>
                    <td style="background-color: #b5b5b5!important;">{{item.betCasinoGivebackProfitPercent}}%</td>
                    <td style="background-color: #b5b5b5!important;">{{item.betSlotGivebackProfitPercent}}%</td>
                    <td style="background-color: #b5b5b5!important;">
                        <span v-if="item.status ==managerConst.ENABLE">정상</span>
                        <span class="text-red" v-if="item.status ==managerConst.DISABLE">블럭</span>
                    </td>
                    <td style="background-color: #b5b5b5!important;">
                        <span v-if="item.registable ==managerConst.ENABLE">정상</span>
                        <span class="text-red" v-if="item.registable ==managerConst.DISABLE">차단</span>
                    </td>
                    <td style="background-color: #b5b5b5!important;">
                        <span v-if="item.moneymoveable ==managerConst.ENABLE">정상</span>
                        <span class="text-red" v-if="item.moneymoveable ==managerConst.DISABLE">차단</span>
                    </td>
                    <td style="background-color: #b5b5b5!important;">
                        <i class="fa fa-pencil text-green" style="cursor: pointer" @click="editPartner(item.id,managerConst.MODE_EDIT)"> 수정</i>
                        <i class="fa fa-save text-green" style="margin-left: 5px;cursor: pointer" @click="addPartner(item.id,managerConst.MODE_SAVE)"> 총판+</i>
                        <i class="fa fa-close text-red" style="margin-left: 5px;cursor: pointer" @click="deleteGeneralAgent(item.id)"> 삭제</i>
                    </td>
                </tr>
                <tr v-for="agent in item.agentList">
                    <td>{{agent.id}}</td>
                    <td>{{agent.account}}</td>
                    <td>{{agent.nickname}}</td>
                    <td>{{agent.userCount}} ({{agent.userCountBlock}})</td>
                    <td>{{agent.rcode}}</td>
                    <td>{{agent.cash|comma}}</td>
                    <td>{{agent.rechargeProfitPercent}}%</td>
                    <td>{{agent.betProfitPercent}}%</td>
                    <td>{{agent.betLeisureGivebackProfitPercent}}%</td>
                    <!--                                                <td>{{agent.betLeisureGivebackUserProfitPercent}}%</td>-->
                    <td>{{agent.betCasinoGivebackProfitPercent}}%</td>
                    <td>{{agent.betSlotGivebackProfitPercent}}%</td>
                    <td v-if="agent.status == managerConst.ENABLE">정상</td>
                    <td v-if="agent.status == managerConst.DISABLE" class="text-red ">블럭</td>
                    <td v-if="agent.registable == managerConst.ENABLE">정상</td>
                    <td v-if="agent.registable == managerConst.DISABLE" class="text-red ">차단</td>
                    <td v-if="agent.moneymoveable == managerConst.ENABLE">정상</td>
                    <td v-if="agent.moneymoveable == managerConst.DISABLE" class="text-red">차단</td>


                    <td>
                        <i class="fa fa-pencil text-green" style="cursor: pointer" @click="editPartner(agent.id,managerConst.MODE_EDIT)"> 수정</i>
                        <i class="fa fa-close text-red" style="margin-left: 5px;cursor: pointer" @click="deleteAgent(agent.id)"> 삭제</i>
                    </td>
                </tr>
            </table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[50, 100, 150, 200]"
                    :page-size="50"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>
    </div>


</template>

<script>
    import Vue from "vue";
    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {manager} from "../../common/administrator/managerMixin";
    import managerConst from "../../common/administrator/managerConst";
    import {Loading} from "element-ui";
    import PartnerSelectorComp from "../../components/administrator/PartnerSelectorComp";
    import {delAgent, delGeneralAgent, getPartnerList} from "../../network/manager/partnerRequest";
    import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
    import {updateGroup} from "../../network/manager/groupRequest";

    Vue.prototype.$partnerEditToast = PartnerEditToast

    export default {
        name: "ManagerPartner",
        components: {PartnerSelectorComp, ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        mixins: [manager],
        data() {
            return {
                user: {
                    status: managerConst.User.STATUS_ENABLE,
                },
                partner: {},
                pageNum: 1,
                pageSize: 5,
                orderBy: 'id DESC',
                pageTotal: 0,
                partnerList: [],
                editParnterId: 0,

            }
        },
        methods: {
            editPartner(id, mode) {
                this.$partnerEditToast({'partnerId': id, 'mode': mode})
            },
            addPartner(generalAgentId, mode) {
                this.$partnerEditToast({'partnerId': generalAgentId, 'mode': mode})
            },
            getPartnerList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.partner.generalAgentId = 0
                getPartnerList(this.partner, this.pageNum, this.pageSize, this.orderBy).then(res => {
                    this.pageTotal = res.data.total;
                    this.partnerList = res.data.data;
                    loadingInstance.close();
                })
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getPartnerList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getPartnerList();
            },
            setPartnerId(partnerId) {
                this.pageNum = 1
                this.user = {}
                this.user.status = managerConst.User.STATUS_ENABLE
                this.user.partnerId = partnerId
                this.getPartnerList()
            },
            deleteGeneralAgent(id){
                this.$confirm('해당 부본사를 삭제 하시겠습니까?(부본사가 총판을 보유할경우 삭제불가합니다)', '부본사 삭제', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    delGeneralAgent(id).then(res=>{

                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '삭제 완료되였습니다'
                            });
                            this.getPartnerList()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }


                    })

                });
            },
            deleteAgent(id){
                this.$confirm('해당 총판을 삭제 하시겠습니까?(총판이 회원을 보유할경우 삭제불가합니다. 기타 총판으로 옴긴후 삭제하세요)', '총판 삭제', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    delAgent(id).then(res=>{

                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '삭제 완료되였습니다'
                            });
                            this.getPartnerList()

                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }


                    })

                });
            }


        },
        created() {
            this.getPartnerList()


            this.$bus.$on('partnerEdited', (calbc) => {
                this.getPartnerList()
            })

        },
        watch: {}
    }
</script>

<style scoped>

</style>